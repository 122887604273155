<template>
   <div class="work-details">
     <!-- <bannerStatic :num="5" :title="'加入郑大智能，遇见更优秀的你'"/> -->
     <div class="width800">
        <a-breadcrumb class="width1000" style="text-align:left;margin:20px auto 40px;">
            <a-breadcrumb-item> <router-link :to="`/`">首页</router-link></a-breadcrumb-item>
            <a-breadcrumb-item><router-link :to="`/Work`">工作机会</router-link></a-breadcrumb-item>
             <a-breadcrumb-item style="color:rgba(0, 0, 0, 0.45)">职位详情</a-breadcrumb-item>
        </a-breadcrumb>
        <h3>{{contant.jobName}}</h3>
        <div class="job-info"><span>{{contant.workPlace}}</span><div class="lineDevider"></div><span>{{contant.salary}}</span></div>
        <div class="block-title">职位描述</div>
        <div class="block-contant" v-html="contant.jobdescription" >
        </div>
        <div class="block-title">职位要求</div>
        <div class="block-contant"  v-html="contant.jobClaim" >
        </div>
        <div class="block-title">工作地点：</div>
        <div class="block-contant"  v-html="contant.address" style="min-height:auto;">
        </div>
        <br/>
        <br/>
        <p>请将简历投送到邮箱：{{email}}</p>
     </div>
   </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/BannerStatic'
import  { newsImg } from '@/config/appSetting'
import { getJobDetails } from '@/api/data'
export default {
  name:'workDetails',
  components: {
    bannerStatic
  },
   data() {
      return {
        contant:{},
        email:''
      };
    },
    methods: {
  },
  mounted(){
    let _this=this
    this.email=sessionStorage.getItem("email")
    getJobDetails({},this.$route.params.id).then(res => {
        if (res.state) {
          _this.contant=res.data
        }
     })
  }
}
</script>
<style lang="less" scoped>
.work-details{
  min-height: 800px;
  padding-top:60px;
  .width800{
    width: 800px;
    text-align: left;
    margin: 50px auto;
    h3{
      font-size: 30px;
      text-align: left;
    }
    .block-title {
        margin: 25px 0 8px;
        font-size: 21px;
        line-height: 1.52;
        color: #1f2329;
    }
    .block-contant {
       min-height: 160px;
       line-height:25px;
    }
     .job-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      color: #646a73;
      font-size: 14px;
      font-weight: 500;
     }
     .lineDevider {
          display: inline-block;
          width: 1px;
          height: 24px;
          background-color: #bbbfc4;
          margin: 0 8px;
      }
  }
}
</style>



