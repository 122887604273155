<template>
  <div class="static-banner-box">
      <div class="img-box" :class="{'bg1':num==1,'bg2':num==2,'bg3':num==3,'bg4':num==4,'bg5':num==5}">
           <p class="" v-html="title" ></p>
      </div>
  </div>
</template>

<script>
export default {
  name: 'BannerStatic',
  props:{
    num:{
      type:Number,
      default:0
    },
    title:{
      type:String,
      default:''
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>

<style scoped lang="less">
.static-banner-box{
  padding-top:60px;
  .img-box{
    position: relative;
    height: 560px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center ;
    p{
      position: absolute;
      top:48%;
      left:0;
      right:0;
      width: 100%;
      text-align: center;
      font-size: 42px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 0px;
      letter-spacing: 0px;
      color: #ffffff;
      width: 1200px;
      margin:0 auto;
    }
  }
}
.bg1{
  background-image: url(../../assets/img/static-aboutUs.jpg)
}
.bg2{
  background-image: url(../../assets/img/static-productBg.jpg)
}
.bg3{
  background-image: url(../../assets/img/static-cooperationBg.jpg)
}
.bg4{
  background-image: url(../../assets/img/static-newsBg.jpg)
}
.bg5{
  background-image: url(../../assets/img/static-joinUs.jpg)
}
</style>
